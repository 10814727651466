<!-- 视频列表 videos-->
<template>
<div>
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <section class="wCenter" v-if="config.c1&&config.c1.isShow">
        <div class="videoList" v-if="list && list.length>0">
            <div class="item hand" v-for="(item,index) in list" :key="index" @click="goDetail(item.id)">
                <div class="imgBox">
                    <img :src="item.coverImgUrl" alt="">
                    <img src="@/assets/icon_play.png" class="icon_play">
                </div>
                <p class="title">{{item.title}}</p>
            </div>
        </div>
        <div v-else class="noData">
            <img src="../../assets/null.png" />
        </div>
        <div class="tabFoot" v-if="config.c1&&config.c1.isShow">
            <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage" @on-page-size-change="changePageSize" :page-size-opts="[9,18,45]" size="small" show-total show-elevator show-sizer></Page>
        </div>
    </section>
</div>
</template>

<script>
import util from "@/libs/util.js";
import {
    getPageConfigByCode,
    getVideoInformationList
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
            searchForm: {
                pageNumber: 1,
                pageSize: 9
            },
            total: 0,
            list: []
        }
    },
    mounted() {
        this.getPageConfigByCode()
        this.getList()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'videoList/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
        getList() {
            getVideoInformationList(this.searchForm).then(res => {
                if (res.success && res.result) {
                    this.list = res.result.records
                    this.total=res.result.total
                }
            })
        },
        goDetail(e) {
            util.pageChange(this.$router, `/video-detail`, {
                id: e
            }, '_blank');
        },
        changePage(v) {
            this.searchForm.pageNumber = v;
            this.getList();
        },
        changePageSize(v) {
            this.searchForm.pageSize = v;
            this.getList();
        },
    }
}
</script>

<style lang="scss">
.tabFoot {
    text-align: center;
    border-top: 0;
}

.noData {
    text-align: center;
    margin: 0.4rem 0;

    img {
        width: 5rem;
    }
}

.videoList {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: .3rem;

    .item {
        margin-bottom: .2rem;
        flex: 0 0 32%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
 
        &:nth-child(3n) {
            margin-right: 0;
        }
        // &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3){
        //     margin-bottom: 0;
        // }

        .imgBox {
            position: relative;
            height: 2.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // border:1px solid #f2f4f6;
            overflow: hidden;
            .icon_play {
                $w: .5rem;
                $ww: 1rem;
                position: absolute;
                top: 50%;
                left: 50%;
                width: $w;
                margin-left: -$w/2;
                margin-top: -$w/2;
                @media (max-width:767px){
                    width: $ww;
                    margin-left: -$ww/2;
                    margin-top: -$ww/2;
                }
            }
            img{
                max-width: 100%;
                // max-height: 100%;
            }
        }

        .title {
            margin-top: .1rem;
            font-size: .24rem;
            padding:0 .2rem;
			margin-bottom: 0.3rem;
			overflow : hidden;
			/*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
            word-break: break-all;
			@media (max-width:767px){
			   font-size: 0.36rem;
			}
        }
        @media (max-width:767px){
            flex:0 0 100%;
            margin:0;
			    margin-bottom: 0.5rem;
			    border-bottom: 1px solid #cfcdcd;
        }

    }
}
</style>
